@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


.pagination {
  @apply flex space-x-2;
}

button {
  @apply px-3 py-1 text-white;
}

button.active {
  @apply border-greenV bg-greenV border text-black;
}

button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

html{
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rv-bg{
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),  url('images/recentVid.png') no-repeat center;
  background-size: cover;
}
.film-bg{
  background: url('/src/images/Film-Strip-Background-PNG-Image\ 1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.gradient-text {
  font-size: 64px;
  font-weight: bold;
  background: linear-gradient(to right, #ff7e5f, #af7bfe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-bg {
  background: url('../src/images/didache\ library.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: black; */
}

.gradient-text {
  background: linear-gradient(105.28deg, #061028 1.95%, #C1C4CB 55.57%, #061028 111.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 1250px) {
  .arrow-right {
      margin-right: 30% !important;
    }
    .arrow-left {
      margin-left: 5% !important;
    }
}
@media screen and (max-width: 900px) {
  .arrow-right {
      margin-right: 40% !important;
    }
    .arrow-left {
      margin-left: 10% !important;
    }
}
@media screen and (max-width: 750px) {
  .arrow-right {
      margin-right: 45% !important;
    }
    .arrow-left {
      margin-left: 20% !important;
    }
}
@media screen and (max-width: 600px) {
  .arrow-right {
      margin-right: 50% !important;
    }
    .arrow-left {
      margin-left: 23% !important;
    }
}
.audio-progress::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #4b576e;
  /* Thumb color */
  cursor: pointer;
  border-radius: 50%;
}

/* Custom Range Slider */
.audio-progress {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #4b576e;
  /* Track color */
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.audio-progress:hover {
  opacity: 1;
}

.audio-progress::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background: #ffffff;
  /* Thumb color */
  cursor: pointer;
  border-radius: 50%;
}

.audio-progress::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #ffffff;
  /* Thumb color */
  cursor: pointer;
  border-radius: 50%;
}

.audio-progress::-ms-thumb {
  width: 15px;
  height: 15px;
  background: #ffffff;
  /* Thumb color */
  cursor: pointer;
  border-radius: 50%;
}

.book-line{
  background: linear-gradient(90deg, #666666 0%, #FFFFFF 25%, #7F7F7F 42.8%, #FFFFFF 62.8%, #6B6B6B 80.8%, #FFFFFF 100%);
}

.slick-slide > div{
  display: flex;
  justify-content: center;
}
.slick-prev:before,
.slick-next:before{
  display: none !important;
}

#carousel .arrow {
  z-index: 40;
}

.albumBg{
  background: linear-gradient(180deg, #0E1931 0%, #374666 100%);
}